import React from 'react'
import './style.less';
import {Row, Col} from 'antd';
import CatListingItem from "../CatListingItem";
import {graphql, StaticQuery} from "gatsby";
import {trackMemberThreeLinkClick} from "../../services/gtm";

class DiscoverMore extends React.Component {
    state = {
        discoverMore: []
    };
    location      = undefined;
    locationTypes = {};

    constructor(props) {
        super(props);

        if (this.props.member) {
            this.props.member.locations.forEach((location, index) => {
                if (location.wordpress_id === parseInt(this.props.locationId)) {
                    this.location = location;
                    return false;
                }
            });
        }

        Object.keys(this.props.locationTypes).forEach(
            (key, index) => {
                let type       = this.props.locationTypes[key];
                let typeOption = {
                    class:          type.acf.class,
                    label:          type.acf.label,
                    listing_colour: type.acf.listing_colour,
                    discover_more:  type.acf.discover_more,
                    value:          type.wordpress_id,
                    children:       [],
                    parent:         undefined,
                    translations:   type.translations
                };
                if (type.wordpress_parent === 0) {
                    // top level type
                    if (this.locationTypes.hasOwnProperty(type.wordpress_id)) {
                        // and it has already been initialised
                        // make sure we don't overwrite any children already set
                        typeOption.children = this.locationTypes[type.wordpress_id].children;
                    }
                } else {
                    // nested type
                    typeOption.parent = type.wordpress_parent;
                    if (!this.locationTypes.hasOwnProperty(type.wordpress_parent)) {
                        // parent is not already set up
                        this.locationTypes[type.wordpress_parent] = {
                            children: []
                        };
                    }
                    this.locationTypes[type.wordpress_parent].children.push(type.wordpress_id);
                }
                this.locationTypes[type.wordpress_id] = typeOption;
            }
        );
    }

    componentDidMount() {
        let discoverMore = [];
        if (this.location === undefined) {
            Object.keys(this.locationTypes).forEach((key, index) => {
                let locationType = this.locationTypes[key];
                if (locationType.parent === undefined && locationType.translations.current === this.props.locale) {
                    discoverMore.push({
                        label_location_type: key,
                        suggestions:         [
                            {
                                type:   key,
                                number: 2
                            }
                        ]
                    });
                }
            });
            discoverMore.sort((a, b) => {
                let order = ['Attractions', 'Atyniad', 'Accommodation', 'Llety', 'Eatery', 'Bwyty', 'Retail & hire', 'Manwerthu'];
                let aIndex = order.indexOf(this.locationTypes[a.label_location_type].label);
                let bIndex = order.indexOf(this.locationTypes[b.label_location_type].label);
                return aIndex - bIndex;
            });
        } else {
            if (this.locationTypes[this.location.type].discover_more) {
                discoverMore = this.locationTypes[this.location.type].discover_more;
            }
        }
        this.setState({discoverMore: discoverMore});
    }

    distance = function (first, second) {
        var p = 0.017453292519943295;    // Math.PI / 180
        var c = Math.cos;
        var a = 0.5 - c((second.latitude - first.latitude) * p) / 2 +
            c(first.latitude * p) * c(second.latitude * p) *
            (1 - c((second.longitude - first.longitude) * p)) / 2;

        return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
    }

    getLocations = (type, number) => {
        let locations = this.props.locations.filter((location, index) => {
            if (location.type
                && (location.type === type || this.locationTypes[type].children.includes(location.type))
                && location.translations.current === this.props.locale) {
                return true;
            }
            return false;
        });

        let filtered = [];
        if (this.location) {
            // location is set, order by distance
            let distances = [];
            locations.forEach((location, index) => {
                distances.push({
                    location: location,
                    distance: this.distance(
                        {latitude: location.latitude, longitude: location.longitude},
                        this.location
                    )
                });
            });
            distances.sort((a, b) => {
                if (a.distance === b.distance) {
                    return 0;
                }
                return (a.distance < b.distance) ? -1 : 1;

            });
            number = parseInt(number);
            number = (distances.length < number) ? distances.length : number;
            for (let i = 0; i < number; i++) {
                filtered.push(distances[i].location);
            }
        } else {
            if (locations.length > 0) {
                let key = Math.floor(Math.random() * locations.length);
                filtered.push(locations[key]);

                if (locations.length > 1) {
                    let used = key;
                    while (key === used) {
                        key = Math.floor(Math.random() * locations.length);
                    }
                    filtered.push(locations[key]);
                }
            }
        }
        return filtered;
    }

    render() {
        return (
            <div className="sub-cat-listing discover-more">
                <Row type="flex" gutter={70}>
                    <Col xs={{span: 24}}>
                        <h2>Discover More</h2>
                        {this.state.discoverMore.map((discover_more, index) => {
                            let type = this.locationTypes[discover_more.label_location_type];
                            return (<div className="cat-group" key={"dm_" + index}>
                                <h3 data-color={type.listing_colour}>{type.label}</h3>
                                <div className="cat-listing">
                                    {
                                        discover_more.suggestions.map((suggestion, index) => {
                                            let locations = this.getLocations(parseInt(suggestion.type), suggestion.number);
                                            let img       = undefined;
                                            return locations.map((location, innerIndex) => {
                                                if (location.member.featured_image === null) {
                                                    img = "https://via.placeholder.com/640/099/FFFFFF/?text=" + location.name;
                                                } else {
                                                    img = location.member.featured_image.localFile.childImageSharp.fluid;
                                                }
                                                let link = location.member.website_url;
                                                if(location.member.membership_level!=="3"){
                                                    link = location.member.path + "?location=" + location.wordpress_id;
                                                }
                                                return <CatListingItem
                                                    key={"sug_" + index + "_" + location.wordpress_id}
                                                    urlPath={link}
                                                    color={this.locationTypes[location.type].listing_colour}
                                                    image={img}
                                                    // Will need to be a fluid gatsby image of 640x640
                                                    title={location.name}
                                                    blurb=""
                                                    location={location}
                                                />
                                            });
                                        })
                                    }
                                </div>
                            </div>);
                        })}
                    </Col>
                </Row>
            </div>
        );

    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query DiscoverMoreLocationList {
                allWordpressWpLocation {
                    nodes {
                        wordpress_id
                        acf {
                            latitude
                            longitude
                            member {
                                amenities
                                membership_level
                                name
                                path
                                snippet
                                snowdonia_pass
                                website_url
                                wordpress_id
                                featured_image {
                                    localFile {
                                        childImageSharp {
                                            fluid {
                                                ...GatsbyImageSharpFluid
                                            }
                                        }
                                    }
                                }
                            },
                            name
                            type
                        },
                        translations {
                            current
                            cy
                            en
                        }
                    }
                },
                allWordpressWpLocationType {
                    nodes {
                        acf {
                            class
                            label
                            listing_colour
                            discover_more {
                                label_location_type
                                suggestions {
                                    number
                                    type
                                }
                            }
                        },
                        translations {
                            current
                            cy
                            en
                        },
                        wordpress_id
                        wordpress_parent
                    }
                }
            }
        `}
        render={(data) => {
            let locations = data.allWordpressWpLocation.nodes.map(function (location) {
                let loc          = location.acf;
                loc.wordpress_id = location.wordpress_id;
                loc.translations = location.translations;
                return loc;
            });
            return <DiscoverMore
                locations={locations}
                locationTypes={data.allWordpressWpLocationType.nodes}
                {...props}
            />
        }}
    />
)
